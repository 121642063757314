import React, { lazy, Suspense } from "react";
import Table from "../../common/components/Table";
import { useGetPlacementsQuery } from "../../app/apiSlice";
import Loader from "../../common/components/Loader";
import { Route, Routes, useNavigate, Link } from "react-router-dom";
import ErrorHandler from "../../common/components/ErrorHandler";
import PermissionsCheck from "../auth/Permissions/PermissionsCheck";
import { SCOPES } from "../auth/Permissions/permissionsMap";
import ProtectedRoute from "../../common/components/ProtectedRoute";
import useHasPermission from "../../common/hooks/useHasPermission";
import useToggle from "../../common/hooks/useToggle";
const PlacementEdit = lazy(() => import("./placementEdit/PlacementEdit"));
const NewPlacement = lazy(() => import("./placementCreation/CreatePlacement"));

const Placements = ({ user }) => {
  const canEditPlacements = useHasPermission([SCOPES.canEditPlacements]);

  return (
    <Routes>
      <Route path="/" element={<PlacementsLayout />} />
      <Route
        path=":placementId/*"
        element={
          <Suspense fallback={<Loader message="Loading Placements..." />}>
            <PlacementEdit />
          </Suspense>
        }
      />
      <Route
        element={<ProtectedRoute isAllowed={!!user && canEditPlacements} />}
      >
        <Route
          path="/new_placement"
          element={
            <Suspense fallback={<Loader message="Loading..." />}>
              <NewPlacement />
            </Suspense>
          }
        />
      </Route>
    </Routes>
  );
};

const PlacementsLayout = () => {
  const navigate = useNavigate();

  const {
    data: records, // Placements
    error, // Errors.
    isLoading, // Loading boolean.
  } = useGetPlacementsQuery(); // Query hook call with options.

  const { visible: brokeredFilterVisible, toggle: brokeredFilterToggle } =
    useToggle();

  // catch error in call.
  if (error !== undefined) {
    return <ErrorHandler err={error} />;
  }

  // Loading Render
  if (isLoading) {
    return <Loader message="Loading Placements..." />; // Loading component.
  }

  // Prepare header for Table component.
  const headers = [
    { text: "Name", id: "name", type: "string" },
    { text: "UUID Copy" }, // Blank header for uuid copy icon.
    { text: "Short Code", id: "legacyID", type: "string" },
    { text: "Legacy Code", id: "legacyCode", type: "string" },
  ];
  // Prepare campaigns for Table component.
  return (
    <>
      <div>
        <div className="grid-container campaigns-toolbar">
          <PermissionsCheck scopes={[SCOPES.canEditPlacements]}>
            <Link to="new_placement" className="btn btn-secondary btn-rounded">
              Create Placement
            </Link>
          </PermissionsCheck>
        </div>
        <Table
          title="Placements"
          headers={headers}
          rows={records}
          customButton={
            <div
              style={{
                marginLeft: "4px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <button
                onClick={() => brokeredFilterToggle()}
                className={
                  brokeredFilterVisible
                    ? "btn-secondary btn-rounded"
                    : "btn-primary btn-rounded"
                }
              >
                {brokeredFilterVisible ? "Brokered" : "Non-Brokered"}
              </button>
            </div>
          }
          customButtonToggle={brokeredFilterVisible}
          bordered={true}
          sortOptions={{ field: "isBrokered", type: "field", order: true }}
          filterFields={["name", "UUID", "legacyID"]}
          formatFunction={({
            UUID: id,
            name,
            isActive,
            legacyID,
            legacyCode,
          }) => {
            return {
              id,
              isActive,
              name: {
                text: name,
                subtext: id,
                onClick: () => navigate(`/placements/${id}/details`),
              },

              clip: {
                icon: "link",
                onClick: () => navigator.clipboard.writeText(id),
                tooltip: "Copied UUID to clipboard!",
              },
              legacyID: legacyID,
              legacyCode: legacyCode,
            };
          }}
        />
      </div>
    </>
  );
};

export default Placements;
