// Roles Object.
export const ROLES = {
  engineering: "ENGINEERING",
  ops: "OPS",
  mgmt: "MGMT",
  accounting: "ACCOUNTING",
  am: "AM",
  bizdev: "BIZDEV",
  eds: "EDS",
  media: "MEDIA",
  sales: "SALES",
  training: "TRAINING",
};

// Scopes Object.
export const SCOPES = {
  // Campaigns
  canViewCampaigns: "can-view-campaigns",
  canEditCampaigns: "can-edit-campaigns",
  canViewCampaignNotes: "can-view-campaign-notes",

  // Placements
  canViewPlacements: "can-view-placements",
  canEditPlacements: "can-edit-placements",

  // Reporting
  canViewReporting: "can-view-reporting",
  canEditReporting: "can-edit-reporting",

  // Ops Tools:
  canViewOps: "can-view-ops",
  canViewEmailSuppression: "can-view-email-suppression",
  canEditEmailSuppression: "can-edit-email-suppression",

  canViewBadWords: "can-view-bad-words",
  canEditBadWords: "can-edit-bad-words",

  canViewDemographics: "can-view-demographics",
  canEditDemographics: "can-edit-demographics",

  canViewTagManagement: "can-view-tag-management",
  canEditTagManagement: "can-edit-tag-management",

  canViewBlockedDomains: "can-view-blocked-domains",
  canEditBlockedDomains: "can-edit-blocked-domains",

  canViewBlockedAreaCodes: "can-view-blocked-area-codes",
  canEditBlockedAreaCodes: "can-edit-blocked-area-codes",

  canViewBlockedEmails: "can-view-blocked-emails",
  canEditBlockedEmails: "can-edit-blocked-emails",

  canViewDarwinGroups: "can-view-darwin-groups",
  canEditDarwinGroups: "can-edit-darwin-groups",

  canViewValidationManagement: "can-view-validation-management",
  canEditValidationManagement: "can-edit-validation-management",

  canViewZipSearchTool: "can-view-zip-search-tool",
};

// Map of Roles to Scopes
export const PERMISSIONS = {
  [ROLES.engineering]: [...Object.values(SCOPES)], // Give Engineering all permisisons.
  [ROLES.ops]: [...Object.values(SCOPES)], // Give Ops all permisisons.

  [ROLES.mgmt]: [
    SCOPES.canViewCampaigns,
    SCOPES.canViewCampaignNotes,

    SCOPES.canViewPlacements,
    SCOPES.canEditPlacements,

    SCOPES.canViewReporting,
    SCOPES.canEditReporting,

    SCOPES.canViewOps,
    SCOPES.canViewEmailSuppression,
    SCOPES.canViewBadWords,
    SCOPES.canEditBadWords,
    SCOPES.canViewDemographics,
    SCOPES.canEditDemographics,
    SCOPES.canViewTagManagement,
    SCOPES.canViewBlockedDomains,
    SCOPES.canViewBlockedEmails,
    SCOPES.canViewBlockedAreaCodes,
    SCOPES.canViewDarwinGroups,
    SCOPES.canViewValidationManagement,
    SCOPES.canViewZipSearchTool,
  ],
  [ROLES.bizdev]: [
    SCOPES.canViewCampaigns,
    SCOPES.canViewCampaignNotes,

    SCOPES.canViewPlacements,
    SCOPES.canEditPlacements,

    SCOPES.canViewReporting,
    SCOPES.canEditReporting,

    SCOPES.canViewOps,
    SCOPES.canViewDarwinGroups,
  ],
  [ROLES.eds]: [
    SCOPES.canViewOps,
    SCOPES.canViewTagManagement,
    SCOPES.canEditTagManagement,
  ],

  [ROLES.accounting]: [],

  [ROLES.media]: [
    SCOPES.canViewCampaigns,
    SCOPES.canViewCampaignNotes,
    SCOPES.canViewPlacements,
    SCOPES.canEditPlacements,

    SCOPES.canViewReporting,
    SCOPES.canEditReporting,
  ],

  [ROLES.am]: [
    SCOPES.canViewCampaigns,
    SCOPES.canViewCampaignNotes,
    SCOPES.canViewPlacements,

    SCOPES.canViewReporting,
    SCOPES.canEditReporting,
  ],

  [ROLES.sales]: [SCOPES.canViewCampaigns, SCOPES.canViewPlacements],

  [ROLES.training]: [],
};
